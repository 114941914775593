import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PrivacyPolicy = () => {

    const contactUsRef = useRef<any>(null);
    let query = useQuery();

    const scrollToBottom = () => {
        contactUsRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        if (query) {
            const isContactUs = query.get("contactUs");
            if (isContactUs) {
                scrollToBottom();
            }
        }
    }, [query]);

    const textBold = (text: string) => (
        <span className={"font-century-gothic-bold"}>{text}</span>
    );

    const underlineText = (text: string) => (
        <span className={"underline"}>{text}</span>
    );

    return (
        <article className={"container py-10 space-y-4"}>
            <p className={"text-center text-2xl font-century-gothic-bold mb-0"}>At Home Articulation, Inc</p>
            <p className={"text-center text-2xl font-century-gothic-bold mt-0"}>
                Privacy Policy
            </p>

            <p>Effective Date:  April 24, 2024</p>

            <p>Welcome to At Home Articulation! We at At Home Articulation, LLC ("Company," "we," "our," or "us") value your privacy as much as we value our own. 
                It's our policy to respect your privacy when it comes to all information we might process while operating our website, web applications, 
                mobile applications, software products, or services ("Services"). Accordingly, we developed this privacy policy ("Policy") for all users of the 
                Services and others with which we interact ("you" or "your") to understand how and why we process your personal information. “You and “your” include users of the Services such as parents, clinicians, and district administrators from which we process minimal Data. 
            </p>

            <p>
                We always seek to improve our Services to you, and that requires that we process information about you and your usage preferences. As we do so, we are absolutely committed to protecting your privacy and the security of your Data.
            </p>

            <p>
                In this Policy, we use the word “Data” to describe all the information we process that relates to you and your use of our Services. 
                "Data" is broken into different categories, which are defined in the "Data We Process and How We Use It" section of this Policy. 
                We may refer to the different categories separately, but when we use the word “Data,” we mean all the different categories described in this Policy. 
                Because of the nature of our Services, the term “Data” also includes the information, including personally identifiable information and sensitive personal information, you gather, process, and upload to the Services or otherwise provide to us. 
                We rely on your compliance with our Terms of Service or any other agreement you have with us, in which you represent and warrant that you have express permission to process all information you upload to the Services.
            </p>

            <p>
                The term “Data” does not apply to information which does not relate to an identified or identifiable individual or to personal information or data 
                rendered anonymous in such a manner that the individual is not or no longer identifiable ("Anonymized Data"). 
                Anonymized Data may be derived from Data. 
                We hold all rights in and may use Anonymized Data for our own purposes in any manner and without attribution or compensation to any person.
            </p>

            <p>
                This Privacy Policy applies to everybody who interacts with our Services. Because different portions of the Privacy Policy will apply differently to the various groups who interact with us, 
                we have tried to clearly categorize the types of Data we process and how we do so. If you have any questions about this Privacy Policy or how we handle your Data, please email us at support@athomearticulation.com.
            </p>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('DESCRIPTION OF SERVICES')}</h1>
                <p>
                    We provide a speech and language pathology software and application platform to empower parents, children (ages 3 to 12), 
                    and clinicians to improve children's speech skills. In this Policy, all tools and services made available in connection with the Services, including any websites, web applications, mobile applications, tools, and any other services that we provide directly to you, whether now known or developed later, are included in the term "Services".
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('LAWFUL BASIS FOR PROCESSING')}</h1>
                <p>
                    Many jurisdictions require that we disclose to you the lawful basis for our processing of your Data. We do that in the "Data We Process and How We Use It" section and otherwise throughout this Policy. 
                    In general, our lawful basis for processing your Data is based on your specific consent or your contract with us.
                </p>
                <p className="mt-4 mb-4">
                    By accessing or using any of the Services, you consent to our processing of your Data as described in this Policy. 
                    If our processing of your Data is based on your consent, you may withdraw your consent at any time, and we will cease processing your Data. 
                    However, in some cases, this may result in your inability to receive partial or full access to the Services, and your withdrawal of consent does not 
                    limit our ability to use the Anonymized Data for use by us in connection with our legitimate business efforts in the future. 
                    In addition, your withdrawal of consent may not prevent us from processing Data if we have processed such Data pursuant to a different lawful basis 
                    or to preserve legal claims. For example, if you give your consent for us to process your Data, but we are also required by law to keep your Data, 
                    that separate “lawful basis” will still apply, even if you withdraw your consent.
                </p>
                <p className="mb-4">
                    When you enter into an agreement with us, either by accessing the Services, by executing an agreement in hard copy, or by clicking “I Accept” or 
                    similar language online or through a mobile application, we will process your Data for the purposes of fulfilling the terms of our contract with you. 
                    In that case, our processing of your Data is based on the contract, so your withdrawal of consent will only be effective after the purposes for processing 
                    that Data have been fulfilled and after we no longer have a legal obligation to keep that Data.
                </p>
                <p>
                    In all cases, we will comply with applicable law and we will cease processing your Data after the legal right, obligation, or other lawful basis expires.
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('INTENDED USERS')}</h1>
                <p>
                    Our Services are directed to users of all ages. Other than for Data processed for the specific purpose of providing the Services to users, we do not knowingly process Data from users who are under 13. 
                    If we become aware that we have processed Data from a person under 13, except to provide the Services to such person, and except to the extent allowed or required by law, 
                    then we will attempt to delete such Data as soon as possible, subject to our obligations under applicable law. If you believe that we have processed Data from a person under 13 in contravention of this 
                    policy or applicable law, please contact us at support@athomearticulation.com.
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('DATA WE PROCESS AND HOW WE USE IT')}</h1>
                <p>
                    Listed below are categories of Data we process when you use our Services. We never sell your Data, and we always have a lawful basis for processing 
                    the Data, but that lawful basis might be different for different categories, and we describe those uses below. 
                    Regardless, we never process the Data for any purpose other than the purpose for which we processed the Data in the first place, unless we get your prior explicit consent.
                </p>
                <div className="mt-4 mb-4">
                    <h2 className="text-lg mt-2 mb-2">{textBold('A. Registration Data')}</h2>
                    <p className="pl-4">
                        1. {underlineText(`Data Description`)}: Registration Data will include the following: {textBold(`name, clinician/parent email address, student grade, and other contact information, or other information you provide us using the Services, both when you register your account and thereafter. Registration Data also includes your username, client type, and other applicable registration information,`)} if any.
                        For clinicians, Registration Data may also include your certification information, information about your work setting, location, business, types of clients, and other information that allows us to determine how you will use the Services.
                    </p>
                    <p className="pl-4 pt-1 pb-1">
                        2. {underlineText(`Lawful Basis for Processing`)}:  Our lawful basis for processing Registration Data is our contract with you and your consent. We can only provide certain of the Services to you if we process the Registration Data, so we need to process that Registration Data during the term of our contract. Even when the Registration Data is not critically necessary to the provision of the Services, we may still process that Registration Data to facilitate our contractual interactions with you.
                    </p>
                    <p className="pl-4">
                        3. {underlineText(`How We Use It and Who We Share It With`)}:  Registration Data is accessible generally only to us, to you, and to other users of the Services with which you connect within the Services. In limited circumstances, we may share your Registration Data with our service providers, which parties help us provide the Services and are under obligations to protect the confidentiality of your Registration Data. We use Registration Data to provide the Services to you. At times, we will share the Registration Data with other third parties at your request. We may also process your Registration Data to offer our own goods or services to you, either directly through emails or through third-party platforms, but you may opt out of those communications at any time. We will never share your username or password with any third party without first obtaining your express written permission. Further, we will never share or sell your sensitive personal information without your written consent.
                    </p>
                </div>
                <div className="mt-4 mb-4">
                    <h2 className="text-lg mt-2 mb-2">{textBold('B. Engagement Data')}</h2>
                    <p className="pl-4">
                        1. {underlineText(`Data Description`)}: Engagement Data consists of all the information you input using the Services, except as otherwise stated in this Policy. It also includes all information that is proprietary to you regarding your use of the Services (other than the data that qualifies as “Usage Data” below) that is processed by the Services. Engagement Data specifically includes the content of any text, personal preferences, and other information you input and include in the Services, including in any chat features, such as feedback, comments, questions, etc. If you are an organization, Engagement Data may include your end-users' personal information.
                    </p>
                    <p className="pl-4 pt-1 pb-1">
                        2. {underlineText(`Lawful Basis for Processing`)}: Our lawful basis for processing Engagement Data is (1) our contract with you, (2) our obligation to provide you with the Services, and (3) our legitimate interest in improving our Services based on the Engagement Data we receive from you.
                    </p>
                    <p className="pl-4">
                        3. {underlineText(`How We Use It and Who We Share It With`)}:  Your Engagement Data is accessible to us, to you, and to other users of the Services with which you connect within the Services. and to limited third parties that we use to provide and improve the Services. If we share your Engagement Data with a third party, that party will be obligated to protect the confidentiality of your Engagement Data. We may also share Engagement Data with other third parties at your request. Both during the term of our agreement with you and thereafter, we may also convert Engagement Data to Anonymized Data, and that Anonymized Data belongs solely to us to use in our sole discretion (including to sell or share Anonymized Data, which is not Data). To the extent we are required to delete any Engagement Data about you, we may still retain Anonymized Data that may have originated as your Engagement Data.
                    </p>
                </div>

                <div className="mt-4 mb-4">
                    <h2 className="text-lg mt-2 mb-2">{textBold('C. Usage Data')}</h2>
                    <p className="pl-4">
                        1. {underlineText(`Data Description`)}: Usage Data consists of the following and similar information:
                    </p>
                    <ul className="pl-16">
                        <li className="list-disc">
                            Information about your interactions with the Services which includes the date and time of any requests you make. This also may include details of any advertising you receive via the Services.
                        </li>
                        <li className="list-disc">
                            Adjustments you make to the default state of the Services, such as custom categories or settings.
                        </li>
                        <li className="list-disc">
                            The timing of the information you post to the Services including messages you send or receive via the Services and your interactions with us, but not including the content of those interactions and messages, which would be included as Engagement Data.
                        </li>
                        <li className="list-disc">
                            Technical data which may include URL information, cookie data, your IP address, the types of devices you are using to access or connect to the Services, unique device IDs, device attributes, network connection type (e.g. WiFi, 4G, LTE, Bluetooth) and provider, network and device performance, browser type, language, information enabling digital, mouse movements (including scrolling data), and GPS (only upon your express permission). You may disable certain of these functions on your device, such as GPS. Please note, however, that if you disable certain functions, your use of the Services may be limited.
                        </li>
                        <li className="list-disc">
                            Rights management, operating system, and application version.
                        </li>
                        <li className="list-disc">
                            Motion-generated or orientation-generated mobile sensor data (e.g. accelerometer or gyroscope), if any, required for the purposes of providing specific features of the Services to you.
                        </li>
                    </ul>
                    <p className="pl-4 pt-1 pb-1">
                        2. {underlineText(`Lawful Basis for Processing`)}: Our lawful basis for processing Usage Data is (1) our contract with you, (2) our obligation to provide you with the Services, and (3) our legitimate interest in improving our Services based on the Usage Data we receive from you.
                    </p>
                    <p className="pl-4">
                        3. {underlineText(`How We Use It and Who We Share It With`)}: Usage Data is accessible to us, to you, and to limited third parties that we use to provide and improve the Services. If we share your Usage Data with a third party, that party will be obligated to protect the confidentiality of your Usage Data. We may also share Usage Data with other third parties at your specific request. We may use Usage Data to make improvements to the Services. Both during the term of our agreement with you and thereafter, we may also convert Usage Data to Anonymized Data, and that Anonymized Data belongs solely to us to use at our sole discretion (including to sell or share Anonymized Data, which is not Data). To the extent we are required to delete any Usage Data about you, we may still retain Anonymized Data that may have originated as your Usage Data.
                    </p>
                </div>

                <div className="mt-4 mb-4">
                    <h2 className="text-lg mt-2 mb-2">{textBold('D. Payment Data')}</h2>
                    <p className="pl-4">
                        1. {underlineText(`Data Description`)}: Payment Data is only processed when your use of the Services is subject to the payment of a fee or other charge. Payment Data is the information necessary to process your payments for the Services. Payment Data will vary depending on the payment method you use but will likely include information such as:
                    </p>
                    <ul className="pl-16">
                        <li className="list-disc">
                            Name;
                        </li>
                        <li className="list-disc">
                            Date of birth;
                        </li>
                        <li className="list-disc">
                            Certain credit card information used to reference a credit card;
                        </li>
                        <li className="list-disc">
                            Address and postal code; and
                        </li>
                        <li className="list-disc">
                            Mobile phone number
                        </li>
                    </ul>
                    <p className="mt-4 pl-8">
                        Please note that we use third-party providers to process Payment Data. The third party's tool is layered over our Services, so your credit card information never hits our system. Further, we only use payment processors that are PCI compliant.
                    </p>
                    <p className="pl-4 pt-4 pb-1">
                        2. {underlineText(`Lawful Basis for Processing`)}: Our lawful basis for processing Payment Data is (1) our contract with you and (2) our legitimate interest in improving our Services based on the Payment Data we receive from you. 
                    </p>
                    <p className="pl-4">
                        3. {underlineText(`How We Use It and Who We Share It With`)}: We only process Payment Data to facilitate payment, and we only communicate it to those parties who are strictly necessary for that purpose.
                    </p>
                </div>

                <div className="mt-4 mb-4">
                    <h2 className="text-lg mt-2 mb-2">{textBold('E. Supplemental Mobile Data')}</h2>
                    <p className="pl-4">
                        1. {underlineText(`Data Description`)}: Where applicable to the Services, Supplemental Mobile Data consists of the following and similar information:
                    </p>
                    <ul className="pl-16">
                        <li className="list-disc">
                            Your voice data - If you give us permission, this enables us to process the voice commands captured via your device microphone to enable you to interact with the Services with your voice. Please note you will always have the ability to turn off the microphone feature. 
                        </li>
                    </ul>
                    <p className="pl-4 pt-4 pb-1">
                        2. {underlineText(`Lawful Basis for Processing`)}: Our lawful basis for processing Supplemental Mobile Data is (1) our contract with you, (2) our obligation to provide you with the Services, and (3) our legitimate interest in improving our Services based on the Supplemental Mobile Data we process.
                    </p>
                    <p className="pl-4">
                        3. {underlineText(`How We Use It and Who We Share It With`)}: We only process Supplemental Mobile Data when you specifically authorize it, and we only share it with those parties who are strictly necessary for the purpose you have authorized.
                    </p>
                </div>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('SHARING YOUR INFORMATION')}</h1>
                <p>
                    Except where a specific limitation is noted above, we may share your Data as follows:
                </p>

                <ol className="list-decimal pl-12 pt-4 pb-4">
                    <li>
                        {textBold('At Your Instruction.')} If you request us to make your Data available to a third party, and such request furthers the purposes of our Services, we will do so.
                    </li>
                    <li className="pt-1 pb-1">
                        {textBold('Sharing with Vendors.')} In certain cases, we use the services of third-party vendors, to assist us in providing the Services. We may share your Data with such vendors solely for that purpose, and we will require those parties to abide by our privacy policies or privacy policies substantially in consonance with ours.
                    </li>
                    <li>
                        {textBold('Third-Party Offers.')} We may allow other companies to offer you their products and services, including offers through our Services, co-branded pages hosted by the third parties, or via email. Whether or not you decide to participate in any such offers is up to you. Such third party will be authorized to process this information in keeping with our contractual relationship with them and in accordance with their own privacy policy and information practices. We do not control these third parties and you agree that we are not liable for their acts, or any failure to act on their part.
                    </li>
                    <li className="pt-1 pb-1">
                        {textBold('Third-Party Advertising:')} Website Visitors. We may use information to describe our Services and to establish advertising and other business relationships with third parties. We may serve you with targeted advertisements based on your personal or profile information, but we do not provide any of this personal or profile information to an advertiser or any third party with the exception of those uses expressly disclosed in this policy. However, if you click or view an ad on our Services then you consent to the likelihood that the advertiser will assume that you meet the targeting criteria, if any, used to display such ad, and as described above, you will be subject to the advertiser’s privacy policy and information collection practices (if any).
                    </li>
                    <li>
                        {textBold('Service Providers.')} We may use a third party to provide specific Services on our behalf, including sending emails to you, conducting surveys, processing transactions, or performing statistical analysis of our Services. In these cases, we may provide certain personal information, such as your name and email address and other financial information necessary for the service to be provided.
                    </li>
                    <li className="pt-1 pb-1">
                        {textBold('Business Transitions.')} Over time, we may grow and reorganize. We may share your Data, including personal information with affiliates such as a parent company, subsidiaries, joint venture partners, or other companies that we control or that are under common control with us, in which case we will require those companies to agree to use your Data in a way that is consistent with this Policy. In the event of a change to our organizations such that all or a portion of Company or its assets are acquired by or merged with a third-party, or in any other situation where Data that we have processed from users would be one of the assets transferred to or acquired by that third-party, this Policy will continue to apply to your information, and any acquirer would only be able to process your Data as per this policy (unless you give consent to a new policy). We will provide you with notice of an acquisition within thirty (30) days following the completion of such a transaction, by posting on our homepage and by email to your email address that you provided to us. If you do not consent to the processing of your Data by such a successor company, subject to applicable law, you may request its deletion from the successor company. In the unlikely event that Company goes out of business, or files for bankruptcy, we will protect your Data, and will not sell it to any third-party, to the extent permitted by applicable law
                    </li>
                    <li>
                        {textBold('Legal Disclosure.')} We may disclose your Data if required to do so by law or in the good faith belief that such action is necessary to conform to applicable law, comply with a judicial proceeding, court order or legal process served on us, protect and defend our rights or property, or investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, or violations of our Terms of Service or other applicable agreement with you.
                    </li>
                </ol>

                <p>
                    If we ever plan to process any Data in the future for any other purposes not identified above and we do not have a separate lawful basis for that new purpose for processing, we will only do so after obtaining your specific consent.
                </p>
                <p>
                    Further, we do not sell or share (as defined in the CPRA) your Data for any purpose.
                </p>

            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('TECHNOLOGIES WE USE')}</h1>
                <p>
                    The technologies we use for automatic Data processing may include the following:
                </p>

                <ol className="list-disc pl-12 pt-4 pb-4">
                    <li>
                        {textBold('Cookies (or browser cookies).')} A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Services. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Services.
                    </li>
                    <li className="pt-1 pb-1">
                        {textBold('Web Beacons.')} Pages of the Services and our emails may contain small electronic files known as web beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit us, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
                    </li>
                    <li className="pt-1 pb-1">
                        {textBold('Widgets.')} We may use technologies such as widgets or APIs to display parts of our Services on sites that are operated by our business partners.
                    </li>
                    <li>
                        {textBold('Other Technologies.')} We may also use device identifiers, local storage, html modifiers, and different types of caching to help us understand the devices and users who access the Services. Those methods include device identifiers that are either hardware-based or software-based, persistent or non-persistent, and which may identify either a device or a software module within a device (such as a web browser).

                    </li>
                </ol>

                <h2 className="text-lg">
                    {textBold(`"Do Not Track" Options`)}
                </h2>

                <p className="pt-1 pb-4">
                    Your web browser(s) may offer a “Do Not Track” option, which allows the individual to signal to operators of websites and web applications and services (including behavioral advertising services) that he or she does not wish such operators to track certain of his or her online activities over time and across different websites. We do our best to support Do Not Track requests but cannot guarantee full support based on the variety of internet browsers and technologies which means that we may process information about your online activity both while you are using the Services and after your use of the Services.
                </p>

            </section>
            
            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('YOUR RIGHTS REGARDING YOUR DATA')}</h1>
                <p>
                    Under applicable data protection, privacy, and other laws, you may have certain rights related to your access and control of your Data. Such rights may include the following:
                </p>

                <ol className="list-decimal pl-12 pt-4 pb-4">
                    <li>
                        The right to access, correct, update, or request deletion of your Data.
                    </li>
                    <li className="pt-2 pb-2">
                        The right to object to processing or restrict the processing of your Data (Please note that if you exercise this right, it may limit or eliminate our ability to provide you the Services).
                    </li>
                    <li>
                        The right to request portability of your Data.
                    </li>
                    <li className="pt-2 pb-2">
                        The right to opt-out of marketing communications we send you. You can exercise this right by clicking the "Unsubscribe" or "Opt-Out" link found in these communications.
                    </li>
                    <li>
                        The right to not be subject to a decision based solely on automated processing, including profiling, known as Automatic Decision Making.
                    </li>
                    <li className="pt-2 pb-2">
                        The right to submit a complaint to any applicable regulatory authority about our processing activities.
                    </li>
                    <li>
                        The right to opt-out of us sharing (as defined in the CPRA) your Data, including for direct marketing purposes, subject to certain legal exceptions.
                    </li>
                    <li className="pt-2">
                        The right to limit use, disclosure, and restrict sensitive personal information (as defined in the CPRA).
                    </li>
                </ol>

                <p>
                    We may use additional processes to verify your identity before we reveal or delete any of your Data, including two-factor or two-step authentication measures to ensure we can identify you.
                    This list may not include all of your rights under applicable laws. If you believe you have additional rights, please contact us using the methods in this Policy.
                </p>
                <p>
                    Further, although we currently do not process Data without consent, if we at any time in the future process Data without your express consent, you may opt-out or withdraw consent at any time.
                </p>
                <p>
                    Please note that exercising any of the above rights may limit or eliminate our ability to provide you the Services. If so, we may terminate the Services due to such requests.
                    We will try to comply with your request(s) as soon as reasonably practicable and at the very least as required under applicable law. Upon receipt of your written request, we will provide you with a copy of your information, although in certain limited circumstances we may not be able to make all relevant information available to you, such as where that information also pertains to another user. In such circumstances we will provide reasons for the denial to you upon request.
                    Please also note that if you do opt-out of receiving marketing-related emails from us, we may still send you messages for administrative or other purposes directly relating to your use of the Services, and you cannot opt-out from receiving those messages while continuing to use the Services.
                </p>
                <p>
                    Further, you may opt-out or disable certain functions on your particular device, preventing us from processing Data. If you disable such features, your ability to use and access the Services may be limited.
                </p>
                <p>
                    To exercise any of these rights, or if you have any questions about our processing of your Data, please contact us at support@athomearticulation.com or at our toll-free number: (801)-726-0614
                </p>

                <h3 className="text-md pt-4 pb-4">
                    {textBold(`A. Privacy for EU/UK Residents`)}
                </h3>

                <p className="pb-4">
                    The Regulation (EU) 2016/679 (General Data Protection Regulation) made effective in Europe on May 25, 2018 ("GDPR") requires that we clearly describe to data subjects the data we process and how we use that data. This Policy does that and if you have any questions for us regarding our data processing, please contact us at support@athomearticulation.com. We comply with the GDPR requirements to the extent they apply to us.
                </p>
                <p className="pb-4">
                    We are based in the United States. By accessing or using the Services or otherwise providing information to us, you understand that your information will be subject to processing in and to the United States and in our other locations.
                </p>
                <p className="pb-4">
                    Due to the nature of our Services, we may act as a “Controller” or “Processor” as defined under the GDPR depending on your engagement with us. If you believe that this role should be defined differently, please contact us at support@athomearticulation.com.
                </p>
                <p className="pb-4">
                    Pursuant to the GDPR, residents of the EU (and the EEA, as applicable) have the right to obtain our confirmation of whether we maintain personal information relating to them in the United States. If you are a resident of Europe, upon request from you, we will provide you with access to the Data that we process about you. Please contact us if you have any questions.
                </p>
                <p className="pb-4">
                    Further, if you are a resident of the United Kingdom (“UK”), to the extent the GDPR as incorporated into UK law pursuant to s.3 of the European Union (Withdrawal Act) 2018 (as amended, the “UK GDPR”) applies to us, we will follow all supplemental requirements under the UK GDPR and you have all rights as a UK citizen under the UK GDPR.
                </p>

                <h3 className="text-md pt-4 pb-4">
                    {textBold(`B. Privacy for California Residents`)}
                </h3>
                <p>
                    California adopted the California Consumer Privacy Act ("CCPA"), which took effect at the beginning of 2020 and has now adopted the California Privacy Rights Act ("CPRA"), which fully took effect January 1, 2023. We comply with the requirements of the CCPA and CPRA to the extent they apply to us.
                </p>
                <p className="pb-4">
                    If you are a California resident, you may request to exercise your rights for any Data we have processed in the 12 months prior to your request. Such request covers any categories, sources, purposes, and, if applicable, third parties to whom we share the Data. Further, you can exercise any of your rights free of discrimination, for example, we cannot increase the price of the Services or decrease the quality of the Services because you exercise your rights.
                </p>
                <p className="pb-4">
                    Due to the nature of our Services, we may act as a “business” or “service provider” as defined under the CCPA and CPRA depending on your engagement with us. If you believe that this role should be defined differently, please contact us support@athomearticulation.com or at our toll-free number: (801)-726-0614
                </p>
                <p className="pb-4">
                    For more information, please direct your questions to us at support@athomearticulation.com or at our toll-free number: (801)-726-0614
                </p>

                <h3 className="text-md pt-4 pb-4">
                    {textBold(`C. Privacy for Other Jurisdictions`)}
                </h3>
                <p>
                    We strive to comply with all data protection and privacy laws in applicable jurisdictions, to the extent such laws apply to us and our Services. Specifically, our processing of your Data may be subject to the Family Educational Rights and Privacy Act ("FERPA") and the Children's Online Privacy Protection Act ("COPPA"). We work with educational institutions to comply with FERPA and COPPA, to the extent such laws apply to us. We strive to be transparent about our data processing activities and have disclosed our practices throughout this Policy.
                </p>

            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('CHOICES AND PRODUCT SUPPORT:')}</h1>
                <p>
                    Your educational agency uses our services to assist with the administration of school-related activities and to provide a streamlined way to process your Data for educational purposes. These uses are specific to each educational agency and are governed by the contract between Company and your educational agency.
                </p>
                <p>
                    Your educational institution determines the information processed using the Services. If you have any questions regarding the processing of the Data your educational institution shares with us, please contact your educational institution directly.
                </p>
                <p>
                    {textBold(`If you have any questions about your rights under any applicable data protection and privacy laws, please contact us support@athomearticulation.com or at our toll-free number: (801)-726-0614`)}
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('SECURITY')}</h1>
                <p>
                    The security of your Data is important to us. We use commercially reasonable efforts to process your Data in a secure environment. We take technical, contractual, administrative, and physical security steps designed to protect Data that you provide to us. We have implemented procedures designed to limit the dissemination of your Data to only such designated staff as are reasonably necessary to carry out the stated purposes we have communicated to you.
                </p>
                <p>
                    User accounts are password-protected. Users are required to protect the confidentiality of their passwords.
                </p>
                <p>
                    If we become aware of a systems security breach by an unauthorized party or that any Data was used for an unauthorized purpose, we will comply with relevant state and other data breach laws. We will notify users of any breach resulting in unauthorized release of data electronically, at minimum, and without unreasonable delay so that you can take appropriate steps. Such notification will include: date of the breach, the types of information that were subject to the breach, general description of what occurred, and steps we are taking to address the breach.
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('THIRD-PARTY SUBPROCESSORS')}</h1>
                <p>
                    It's important to us that we process your Data in a safe and secure manner. To help us provide, maintain, protect, and improve our Services, we share Data with other partners, vendors, and trusted organizations to process it on our behalf in accordance with our instructions, this Policy, and any other appropriate confidentiality, security, or other requirements we deem appropriate.  These third parties will only have access to the Data they need to help us provide the Services.
                </p>
                <p className="pt-4">
                    You can find information on these partners and subprocessors we work with below, including what Data we share with them or they provide to us, the service they provide for us, and links to their respective privacy policies. This list may change over time, and we’ll work hard to keep it up-to-date.
                </p>
                <p className="pt-4">
                    {underlineText("Intercom")} for hosting our help desk articles and for facilitating our chat help desk for our clinicians, parents and customers.
                </p>
                <p>Data Shared: Registration Data.</p>
                <p className="pt-4">
                    {underlineText("Mailchimp")} for sending registration, onboarding, and communication email notification messages.
                </p>
                <p>Data Shared: Registration Data.</p>
                <p className="pt-4">
                    {underlineText("SendGrid (Acquired by Twillio)")} for sending registration, onboarding, and communication email notification messages.
                </p>
                <p>Data Shared: Registration Data.</p>
                <p className="pt-4">
                    {underlineText("Google Analytics")} for general analytics on demographics and number of visitors using the application.
                </p>
                <p>Data Shared: Registration Data and some Usage Data. All information is sent anonymously.</p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('DATA RETENTION')}</h1>
                <p className="italic">
                    We process Data until it is deleted, or until we no longer need it to provide you with the Services. We will not retain student personal information for any longer than is necessary for educational purposes and legal obligations, or to provide the Services for which we process the student personal information. In addition, we only process student personal information for as long as the student's account is active, unless we are required by law or the student's school to retain it, or need it to protect the safety of our users. Note that some content may be processed after an account is deleted for school legal compliance reasons (e.g. maintenance of "education records" under FERPA or "student records" under various state student privacy laws).
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 className="text-xl mb-2">{textBold('AMENDMENT OF THIS PRIVACY POLICY')}</h1>
                <p>
                    We may revise this Policy from time to time. You can see when the last update was by looking at the "Effective Date “at the top of this page. We won't reduce your rights under this Policy without your explicit consent. If we make any significant changes, we'll provide prominent notice by posting a notice on the Services or our website and notifying you by email (using the email address you provided) within 30 days before the change takes effect, so you can review and make sure you know about them.
                </p>
                <p>
                    We encourage you to review this Policy from time to time, to stay informed about our processing of Data through the Services and our website. If you don't agree with any changes to the Policy, you may terminate your account. By continuing to use the Services or our website after the revised Policy has become effective, you acknowledge that you accept and agree to the current version of the Policy.
                </p>
            </section>

            <section className="mb-2 mt-2">
                <h1 ref={contactUsRef} className="text-xl mb-2">{textBold('CONTACT US')}</h1>
                <p>
                    You can help by keeping us informed of any changes such as a change of your personal contact information. If you would like to access your information, if you have any questions, comments, or suggestions or if you find any errors in our information about you, please contact us at support@athomearticulation.com or at our toll-free number: (801)-726-0614. If you have a complaint concerning our compliance with applicable privacy laws, we will investigate your complaint and if it is justified, we will take appropriate measures.
                </p>
            </section>
        </article>
    );
};

export default PrivacyPolicy;
